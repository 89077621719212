import React from "react"
import { Collapse, Input, Form, Icon, Button, Spin, InputNumber, Modal, Row, Col } from 'antd'
import { Helmet } from "react-helmet"
import SiteLayout from "../components/SiteLayout";
import Start from "../components/Sections/Start";
const Panel = Collapse.Panel;

// @ts-ignore
import styles from './hjelp.module.scss';

/*

*/


export default ({ children }) => (
    <SiteLayout>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Oss | Hjelp</title>
            <script>{`
                    {
            window.intercomSettings = {
                app_id: "wpsqtjpa"
            };
            }`}
            </script>
            <script>{`
                    {
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',intercomSettings);}else{var d=document;var i=function(){i.c(arguments)};i.q=[];i.c=function(args){i.q.push(args)};w.Intercom=i;function l(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/wpsqtjpa';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);}if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()
            }`}
            </script>
        </Helmet>
        <div className={styles.smallcontainer}>

            <h1>HJELP</h1>
            <Start  moreButton={true} /> 
            <div className={styles.introtext}>Trenger du hjelp? Ta en titt i listen under. Om det ikke skulle hjelpe ta kontakt ved trykke på snakkeboblen nede til&nbsp;høyre.  Den beste måten å kontakte Oss på og å få oppdatert informasjon er ved å sammtykke til at Oss kontakter deg, samt å bruke support chatten i appen.
            </div>
            <Row style={{ marginLeft: "auto", marginRight: "auto"}} type="flex" justify="space-around" align="top">
                    <Col xs={24} sm={12} style={{ marginTop: 24 }}>
                   <h3 id="oppkobling"> <Icon type="api" style={{ color: 'rgba(62,3,63,.5)' }} />&nbsp;Oppkobling </h3>
                    <Collapse bordered={false}>
                <Panel header="Hvordan komme i gang med Oss-brikken?" key="1" className={styles.panel}>
                        <div className={styles.text}>
                           
                        1.	HAN-porten må åpnes. Kontakt din nettleverandør og be dem åpne HAN-porten på strømmåleren din<bR /><bR />
                        2.	Plugg Oss-brikken inn i HAN-porten. Den finner du på strømmåleren din, passer bare ett sted. <bR /><bR />   
                        3.	Oss-brikken vil starte å blinke. Du kan lese om hva de ulike blinkene betyr under punktet "hva betyr lysene på Oss-brikken"<bR /><bR />
                        4.	Oss-brikken fikser seg selv. Oss-brikken vil lades og oppdateres automatisk, dette tar ca. 2-3 timer.<bR /><bR />
                        5.	Du får beskjed når brikken er klar. Når alt er klart vil du du få beskjed i appen. <bR /><bR />
                        <p>Trenger du hjelp kan du kontakte oss via chatten i appen. </p>
                    </div>
                </Panel>
                <Panel header="Hvordan aktiverer jeg HAN-porten?" key="2" className={styles.panel}>
                        <div className={styles.text}>
                            Ta kontakt med din netteier for å få aktivert HAN-porten.<bR />
                            <a href="https://www.nve.no/stromkunde/smarte-strommalere-ams/">Les mer om de nye målerne på NVE sine sider</a><bR />
                            

                        </div>
                    </Panel>
                    <Panel header="Hvordan vet jeg om HAN-porten har blitt aktivert?" key="3" className={styles.panel}>
                        <div className={styles.text}>
                        Du skal motta en bekreftelse fra din nettleverandør når HAN-porten har blitt aktivert. <bR /><bR />Du kan også sjekke blinkemønsteret på brikken. <bR />
                        <ul>
                            <li>Ingen blink - Jeg har Aidon måler
                            <p> - HAN-porten er ikke aktivert</p></li>
                            <li>Ett (1 ) og tre (3) røde blink – Jeg har Kaifa eller Kamstrup måler
                            <p> - HAN-porten er ikke aktivert</p></li>
</ul>


                        </div>
                    </Panel>
                    <Panel header="Hvor lang tid tar det fra jeg har koblet i Oss-brikken til jeg får data i appen?" key="4" className={styles.panel}>
                        <div className={styles.text}>
                        Hvis du har fått åpnet HAN-porten på strømmåleren din og Oss-brikken har god dekning, vil det ta 2-3 timer fra brikken er koblet til, til du får data i appen.
                            

                        </div>
                    </Panel>
                </Collapse>
                    </Col>
                    <Col xs={24} sm={12} style={{ marginTop: 24 }}>
                    <h3 id="appen"><Icon type="appstore" style={{ color: 'rgba(62,3,63,.5)' }} />&nbsp;Oss-appen</h3>
                    <Collapse bordered={false}>
                    <Panel header="Hvordan logge inn i Oss-appen?" key="1" className={styles.panel}>
                        <div className={styles.text}>
                        Når du har åpnet Oss-appen klikker på "til innlogging er du grei". Du legger inn e-postadressen du brukte ved registrering, og du vil motta en påloggingskode på mail. Obs obs, kan hende denne kommer i søppelpost.   
                    </div>
                    </Panel>
                    <Panel header="Hvorfor mottar jeg ikke påloggingskode på e-post?" key="2" className={styles.panel}>
                        <div className={styles.text}>
                        Du må bruke samme e-postadresse som du brukte ved registrering. Sjekk om e-post med påloggingskode har kommet i søppelpost.  
                    </div>
                    </Panel>
                    <Panel header="Hvorfor får jeg ikke opp data i appen?" key="3" className={styles.panel}>
                        <div className={styles.text}>
                        1.	Sjekk at HAN-porten på måleren din er åpnet. Har du ikke bedt din nettleverandør om å få den åpnet, må du gjøre dette. Blinkemønsteret på brikken forteller deg om HAN-porten har blitt åpnet. Les mer under punktet "hva betyr lysene på Oss-brikken"<bR />
                        <bR />
2.	Sjekk at målernummer registrert på deg, er det samme målernummeret som brikken er koblet i.<bR /><bR />

3.	Sjekk om Oss-brikken har god nok dekning. De ulike blinkene på Oss-brikken indikere dette, les mer under punket "hva betyr lysene på Oss-brikken"<bR /><bR />



                        </div>
                    </Panel>
                    <Panel header="Hvorfor er det 0A i Fase 2?" key="4" className={styles.panel}>
                        <div className={styles.text}>
                            Aidon - At fase 2 er oppgitt i 0 har rett og slett med hvordan måleren er konstruert og formatet den bruker på HAN-porten i dag. Den har nemlig bare to målesystemer og oppgir disse i fase 1 og fase 3. Forbruk i fase 2 er derfor inkludert i deler av tallet som er oppgitt fase 1, og deler oppgitt i fase 3. Vi jobber med en mulig metode for å estimere hva det faktiske forbruket er i hver fase, men enn så lenge er ikke dette klart.
                        </div>
                    </Panel>
                    <Panel header="Hvordan tolke informasjonen i Oss-appen?" key="5" className={styles.panel}>
                        <div className={styles.text}>
                        <b>Reaktiv effekt </b>
<p>Reaktiv effekt er i prinsippet effekt du ikke betaler noe for. Alle elektroniske apparater gir i større eller mindre grad utslag på reaktiv effekt. Typisk for vanlige husholdninger vil det være utstyr som kjøleskap, fryser, LED belysning og induksjonstopper som generere mest. </p>

<b>Målerstand </b>
<p>Viser hvor mye strøm som er brukt i boligen, fra måler ble installert. </p>

<b>Spenning (V) </b>
<p>Norsk forskrift om spenningskvalitet har satt grenser for hvor store avvik eller forstyrrelser en normal spenning kan ha. Den mest kjente og brukte grensen er tillatt spenningsvariasjon innenfor pluss/minus 10%. I et 230 V nett kan da spenningen ligge innenfor 207 og 253 V.</p>

<p>Har du større avvik, anbefaler vi deg å kontakte din nettleverandør, da dette kan skade elektriske apparater. </p>

<b>Strøm (A) </b>
<p>Måleenheten for elektrisk strømstyrke (hovedsakelig i ledninger) kalles ampere (A). Jo mer strøm du bruker, jo høyere vil dette tallet være. </p>

<b>Watt og kWh </b>
<p>Watt (W) er en måleenhet for elektrisk effekt. Når strøm passerer fra stikkontakten og gjennom en innplugget ledning, og til slutt kommer frem til brødristeren eller vannkokeren din, har den avgitt en effekt.</p>
<p>
Har du en panelovn på 1000W, om står på i en time, vil du i løpet av denne timen ha brukt 1kWh. </p>


                        
                        </div>
                    </Panel>
                    </Collapse>
                    </Col>
                    </Row>
                    <Row style={{ marginLeft: "auto", marginRight: "auto"}} type="flex" justify="space-around" align="top">
                    <Col xs={24} sm={12} style={{ marginTop: 24 }}>
                    <h3 id="brikken"><Icon type="login" style={{ color: 'rgba(62,3,63,.5)' }} />&nbsp;Oss-brikken</h3>
                    <Collapse bordered={false}>
                    <Panel header="Hva betyr lysene på Oss-Brikken" key="1" className={styles.panel}>
                        <div className={styles.text}>
                            <ul>
                                <li>Ingen blink - Jeg har Aidon måler
                                    <ul>
                                        <li>
                                        HAN-porten er ikke aktivert
                                        </li>
                                    </ul>
                                </li>
								<li>Tre (3) røde blink
                                    <ul>
                                        <li>
                                            HAN-porten er ikke aktivert
                                        </li>
                                    </ul>
                                </li>
								<li>Ett (1) hvitt og to (2) røde blink
                                    <ul>
                                        <li>Du har akkurat plugget Oss-brikken til HAN-porten og den kobler seg opp til Oss-plattformen, tar ca. 2-3 timer</li>
                                        <li>
                                        Ingen dekning for Oss-brikken<i> - Prøv å flytt brikken ut av sikringsskapet</i>
                                        </li>
                                    </ul>
                                </li>
                                <li>En til Fire (1-4) hvite blink hvert femte sekund 
                                    <ul>
                                        <li>
                                        Oss-brikken lader
                                        </li>
                                    </ul>
                                </li>
                                <li>En til Fire (1-4) hvite blink hvert tiende sekund
                                    <ul>
                                        <li>
                                        Alt ok med Oss-brikken
                                        <ul>
                                            <li><b>Frekvensen på den hvite blinkingen viser hvor god dekning Oss-brikken har.</b></li>
                                            <li>Ett (1) hvitt blink hvert tiende sekund indikerer dårlig dekning</li>
                                            <li>Fire (4) hvite blink hvert tiende sekund indikerer god dekning.</li>
                                        </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>Ett (1) hvitt blink hvert andre sekund
                                    <ul>
                                        <li>
                                        Laster ned og oppdaterer firmware på Oss-brikken
                                        </li>
                                    </ul>
                                </li>
                                
                            </ul>
                        </div>
                    </Panel>
                    <Panel header="Oss-Brikken og mobildekning" key="2" className={styles.panel}>
                        <div className={styles.text}>
                          Oss-brikken er avhengig av mobildekning, det kan derfor være lurt å forsøke å flytte den ut av skapet om du opplever at den ikke klarer å få kontakt med Oss. ( Se "Hva betyr lysene på Oss-Brikken" )
                    </div>
                    </Panel>
                    <Panel header="Kan Oss-brikken stå i sikringsskap som er plassert ute? " key="3" className={styles.panel}>
                        <div className={styles.text}>
                        Ja, Oss-brikken tåler temperatur fra +70 ned til -40. Den kan til og med stå ute i vær og vind, så lenge den er montert slik at kabelen henger ned.  
                    </div>
                    </Panel>
                    <Panel header="Trenger Oss-brikken ekstern strøm forsyning? " key="4" className={styles.panel}>
                        <div className={styles.text}>
                        Nei, Oss-brikken henter strøm fra selve strømmåleren. Du trenger ikke tenke på batteri eller stikkontakt for å få den til å virke. 
                    </div>
                        </Panel>


                    </Collapse>
                    </Col>
                    <Col xs={24} sm={12} style={{ marginTop: 24 }}>
                    <h3 id="praktisk"><Icon type="info-circle" style={{ color: 'rgba(62,3,63,.5)' }} />&nbsp;Praktisk Info</h3>
                    <Collapse bordered={false}>
              
               
               
                    
                    
              <Panel header="Jeg har byttet måler eller flyttet etter jeg registrerte meg, hva gjør jeg? " key="1" className={styles.panel}>
                  <div className={styles.text}>
                  Ta kontakt med oss via chatten i appen eller på hei@oss.no. Legg ved ditt nye målernummer, målepunktID og adresse, så får vi oppdatert dette på brukeren din. 
              </div>
              </Panel>
            
              <Panel header="Har dere et API?" key="2" className={styles.panel}>
                  <div className={styles.text}>
                  Vi har et public API som du finner <a target="_BLANK" href="https://api.services.oss.no/swagger/index.html">her</a>.
              </div>
              </Panel>
              <Panel header="Kan Oss-brikken integreres med annet smarthus-utstyr?" key="3" className={styles.panel}>
                  <div className={styles.text}>
                  Foreløpig er tanken at andre leverandører skal kynne benytte våre data og analyser inn i sine systemer via vårt API. Dette er nå tilgjengelig i første versjon <a target="_BLANK" href="https://api.services.oss.no/swagger/index.html">her</a>.
              </div>
              </Panel>


             
            
             
              <Panel header="Chat med Oss" key="4" className={styles.panel}>
                  <div className={styles.text}>
                     Den beste måten å kontakte Oss på og å få oppdatert informasjon er ved å sammtykke til at Oss kontakter deg, samt å bruke support chatten i appen. Det gjør at vi fortere kan hjelpe deg da vi får opp all info vi trenger om deg og din bruker med en gang. <bR /><bR />Noen har etterspurt et telefonnummer til oss, det har vi bevisst valgt å ikke ha på våre sider. Rett og slett fordi vi ikke har kapasitet til å ta supporthenvendelser via telefon, slik vi er rigget nå. Vi håper på forståelse for det.
                  </div>
              </Panel>
            
              
      </Collapse>
                    </Col>
            </Row>
            
        </div>
       
    </SiteLayout>
)
class App extends React.Component {
    state = { visible: false }
    showModal = () => {
      this.setState({
        visible: true,
      });
    }
    handleOk = (e) => {
      console.log(e);
      this.setState({
        visible: false,
      });
    }
    handleCancel = (e) => {
      console.log(e);
      this.setState({
        visible: false,
      });
    }
    render() {
      return (
        <div>
          <Button type="primary" onClick={this.showModal}>Open</Button>
          <Modal
            title="Basic Modal"
            visible={this.state.visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <p>Some contents...</p>
            <p>Some contents...</p>
            <p>Some contents...</p>
          </Modal>
        </div>
      );
    }
  }
  
<App />